import React from 'react';
import { EmployeeContainer, EmployeeImage, Title, PhoneMailLink, } from '@@Pages/ContactPage/EmployeeTile/styled';
import { ThumbnailUtils } from '@adsel/libstorefront';
export const EmployeeTile = ({ imageUrl, title, email, phoneNumber, }) => {
    return (React.createElement(EmployeeContainer, null,
        React.createElement(EmployeeImage, { url: ThumbnailUtils.getImageSrc({
                image: imageUrl ? imageUrl : '',
                entity: '',
            }), role: "img", "aria-label": title || undefined, title: title || undefined }),
        title && React.createElement(Title, null, title),
        email && React.createElement(PhoneMailLink, { href: `mailto:${email}` }, email),
        phoneNumber && (React.createElement(PhoneMailLink, { href: `tel:${phoneNumber}`, underline: true }, phoneNumber))));
};
