import { ProductUtils } from '@adsel/libstorefront';
export const getProductLink = (product, route) => {
    if (!product) {
        return '';
    }
    if (ProductUtils.isConfigurableProduct(product)) {
        return product.slug
            ? `/${route || 'product'}/${product.slug}`
            : `/${route || 'product'}/${product.url_key}`;
    }
    else {
        return `/${route || 'product'}/${product.url_key}`;
    }
};
