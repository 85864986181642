import React, { useState, useEffect } from 'react';
import { useLang } from '@@Hooks/useLang';
import { getProductLink } from '@@Storefront/utils/get-product-link';
import { PriceUtils, ThumbnailUtils } from '@adsel/libstorefront';
import { Ratio } from '@@Components/Ratio';
import { ProductContainer, ProductName, TextContainer, PriceContainer, BeforePrice, Price, StyledButton, } from '@@Components/ProductTile/styled';
export const ProductTile = ({ product, }) => {
    var _a;
    const { translate } = useLang();
    const [price, setPrice] = useState({
        price: null,
        price_incl_tax: null,
        type: null,
    });
    const getProductImage = (product) => {
        return ThumbnailUtils.getImageSrc({
            image: product.image,
            width: 600,
            height: 320,
            entity: '',
        });
    };
    useEffect(() => {
        var _a;
        const finalPrice = (_a = product === null || product === void 0 ? void 0 : product.prices) === null || _a === void 0 ? void 0 : _a.final_price;
        if (finalPrice) {
            setPrice(PriceUtils.getFormattedPrice(finalPrice.net, finalPrice.gross));
        }
    }, [(_a = product === null || product === void 0 ? void 0 : product.price) === null || _a === void 0 ? void 0 : _a.final_price]);
    return (React.createElement(React.Fragment, null, product && (React.createElement(ProductContainer, null,
        React.createElement("a", { href: getProductLink(product, '') },
            React.createElement(Ratio, { bg: getProductImage(product), bgSize: "contain", bgRepeat: "no-repeat", height: "200px" })),
        React.createElement(TextContainer, null,
            React.createElement(ProductName, null, product.name),
            React.createElement(PriceContainer, null,
                React.createElement(BeforePrice, null, translate('common.from')),
                React.createElement(Price, null, price === null || price === void 0 ? void 0 : price.price_incl_tax))),
        React.createElement("a", { href: getProductLink(product, '') },
            React.createElement(StyledButton, null, translate('common.check_out')))))));
};
