import { __awaiter } from "tslib";
import { ThumbnailUtils } from '@adsel/libstorefront';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '@@Components/Spinner';
import { MainContainer } from '@@Components/MainContainer';
import { Breadcrumbs } from '@@Components/Breadcrumbs';
import { Link } from '@@Components/Link';
import { BlogFilterBar } from '@@Components/BlogFilterBar';
import { TranslatePlural } from '@@Client/lang';
import { useLang } from '@@Hooks/useLang';
import { getBlogPost, getBlogCategories, getBlogPostFromCategory, } from '@@Storefront/actions/blog';
import { blogCategories } from '@@Storefront/selectors/blog';
import dayjs from 'dayjs';
import reverse from 'lodash-es/reverse';
import slice from 'lodash-es/slice';
import 'dayjs/locale/pl';
import { PageSeo } from '@adsel/components/src/PageSeo';
import { trimCharsAndAddDots } from '@@Helpers/stringHelper';
import { BlogButtonsContainer, BlogEntriesGrid, BlogEntryHeader, BlogEntryImageContainer, BlogEntryInner, BlogPageContainer, BlogPostContainer, BlogPostShortContent, BlogPostTitle, BlogSortRow, CategoriesWrapper, LoadingContainer, ShowMoreButton, } from './styled';
dayjs.locale('pl');
const BlogPage = (props) => {
    var _a, _b, _c, _d, _e;
    const { translate } = useLang();
    const categories = useSelector(blogCategories);
    const [isLoading, setIsLoading] = useState(true);
    const [blogPosts, setBlogPosts] = useState([]);
    const [postCategories, setBlogCategories] = useState([]);
    const [postLimit, setPostLimit] = useState(9);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [mainCategory, setMainCategory] = useState(null);
    const [blogPostsAmount, setBlogPostsAmount] = useState();
    const getCategoryLabel = (categoryIds) => categories === null || categories === void 0 ? void 0 : categories.map((category) => categoryIds.includes(category.id.toString()) && category.name);
    const increaseDisplayPostsLimit = () => setPostLimit(postLimit + 9);
    const showIncreaseLimitButton = () => {
        if (postLimit <= blogPosts.length) {
            return (React.createElement(ShowMoreButton, { onClick: () => increaseDisplayPostsLimit() }, translate('buttons.show_more')));
        }
    };
    useEffect(() => {
        /*if (props.category) {
          setTags({
            title: props.category.meta_title,
            description: props.category.meta_description,
            keywords: props.category.meta_keywords
          });
        } else if (categories) {
          const selectedSubcategory = categories?.find(category => category.id === Number(props.categoryId));
          setSelectedSubcategory(selectedSubcategory);
        } else {
          setSelectedSubcategory(null);
        }*/
        if (props.category) {
            setSelectedSubcategory(props.category);
        }
    }, [props.category]);
    const getActivePosts = (posts) => {
        return posts === null || posts === void 0 ? void 0 : posts.filter((post) => Number(post.status) === 2);
    };
    useEffect(() => {
        const getPosts = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            setIsLoading(true);
            const categoryId = (_a = props.category) === null || _a === void 0 ? void 0 : _a.id;
            /**
             * All posts are needed to select existing categories
             */
            const allBlogPosts = yield getBlogPost();
            setBlogPostsAmount(allBlogPosts.length);
            let blogPosts = allBlogPosts.slice(0, postLimit);
            blogPosts = getActivePosts(blogPosts);
            if (categoryId) {
                const blogPosts = yield getBlogPostFromCategory(categoryId);
                blogPosts.items = getActivePosts(blogPosts === null || blogPosts === void 0 ? void 0 : blogPosts.items);
                if ((_b = blogPosts === null || blogPosts === void 0 ? void 0 : blogPosts.items) === null || _b === void 0 ? void 0 : _b.length) {
                    setBlogPostsAmount((_c = blogPosts === null || blogPosts === void 0 ? void 0 : blogPosts.items) === null || _c === void 0 ? void 0 : _c.length);
                    setBlogPosts(slice(reverse(blogPosts.items), 0, postLimit));
                }
            }
            else
                setBlogPosts(blogPosts);
            /**
             * Categories available in posts
             */
            const availablePostCategories = blogPosts
                .map((post) => {
                return post === null || post === void 0 ? void 0 : post.category_ids;
            })
                .flat(Infinity)
                .filter((v, i, a) => a.indexOf(v) === i);
            setBlogCategories(availablePostCategories);
            setIsLoading(false);
        });
        getPosts();
        getBlogCategories();
    }, [postLimit, props.categoryId]);
    return (React.createElement(React.Fragment, null, isLoading ? (React.createElement(LoadingContainer, null,
        React.createElement(Spinner, { size: 40, speed: "0.5s" }))) : (React.createElement(React.Fragment, null,
        React.createElement(PageSeo, { seo: {
                title: (_a = props.category) === null || _a === void 0 ? void 0 : _a.meta_title,
                description: (_b = props.category) === null || _b === void 0 ? void 0 : _b.meta_description,
                keywords: (_c = props.category) === null || _c === void 0 ? void 0 : _c.meta_keywords,
            } }),
        React.createElement(BlogPageContainer, null,
            React.createElement(MainContainer, null,
                React.createElement(Breadcrumbs, null,
                    React.createElement(Link, { to: "/aktualnosci" }, translate('pages.news'))),
                React.createElement(BlogFilterBar, { categories: postCategories, currentCategoryId: ((_d = props.category) === null || _d === void 0 ? void 0 : _d.id) ? String((_e = props.category) === null || _e === void 0 ? void 0 : _e.id) : null }),
                React.createElement(BlogSortRow, null,
                    React.createElement(TranslatePlural, { count: blogPostsAmount, id: "plural.news" })),
                React.createElement(BlogEntriesGrid, null, blogPosts === null || blogPosts === void 0 ? void 0 : blogPosts.map((entry, index) => (React.createElement(Link, { key: index, to: `/aktualnosci/${entry.url_key}` },
                    React.createElement(BlogPostContainer, null,
                        React.createElement(BlogEntryImageContainer, null,
                            React.createElement("img", { alt: (entry === null || entry === void 0 ? void 0 : entry.name) || undefined, title: (entry === null || entry === void 0 ? void 0 : entry.name) || undefined, src: ThumbnailUtils.getImageSrc({
                                    image: entry.featured_image,
                                    width: 439,
                                    height: 249,
                                    fit: '',
                                    entity: '/blog/',
                                }) })),
                        React.createElement(React.Fragment, null,
                            React.createElement(BlogEntryInner, null,
                                React.createElement(BlogEntryHeader, null,
                                    React.createElement(CategoriesWrapper, null, getCategoryLabel(entry.category_ids).map((element, index) => element && React.createElement("span", { key: index }, element))),
                                    React.createElement("p", null, dayjs(entry.created_at).format('DD/MM/YYYY'))),
                                React.createElement(BlogPostTitle, null, trimCharsAndAddDots(entry.name, 70)),
                                React.createElement(BlogPostShortContent, null, entry.short_content)))))))),
                React.createElement(BlogButtonsContainer, null, showIncreaseLimitButton())))))));
};
export default BlogPage;
